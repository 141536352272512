




















import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    // avatar: () => import('@/modules/community/common/components/avatar.vue'),
    'follow-button': () => import('./follow-button.vue'),
  },
})
export default class FollowingItem extends Vue {
  @Prop() item?: any

  redirectTag(tagContent: string) {
    const searchTerm = tagContent.replaceAll('#', '')
    this.$router.push(`/search/forum?text=${searchTerm}`)
  }
}
